<template>
  <div>
    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <div else>
      <sections-slider
        v-if="$route.params.items == `New Stores`"
        :list="newStore"
        :subTitle="$route.params.items"
        :hasBackground="true"
        :showTitle="true"
        :hasButton="false"
        :withoutSlider="true"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box product-listing-div">
            <router-link
              style="text-decoration: none; color: inherit"
              :to="{
                name: `shopDetails`,
                params: {
                  id: listItem._id,
                  name: listItem.office.officeName,
                },
              }"
            >
              <items-item :itemWhiteBg="true" :product="listItem"> </items-item>
            </router-link>
          </div>
        </template>
      </sections-slider>
      <sections-slider
        v-if="$route.params.items == `Suggested For You`"
        :list="itemSuggestions"
        :subTitle="$route.params.items"
        :hasBackground="true"
        :hasButton="false"
        :showTitle="true"
        :withoutSlider="true"
        class="suggested-slider"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div w-100"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>

      <sections-slider
        v-if="$route.params.items == `What's Trending`"
        :list="trendingItems"
        :subTitle="$route.params.items"
        :hasBackground="true"
        :hasButton="false"
        :showTitle="true"
        :withoutSlider="true"
        class="suggested-slider"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div w-100"
            >
            </items-item>
          </div>
        </template>
      </sections-slider>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  computed: {
    ...mapState({
      newStore: (state) => state["product"].newStores,
      trendingItems: (state) => state["product"].trendingItems,
      itemSuggestions: (state) => state["product"].itemSuggestions,
      currentPosition: (state) => state.location.currentPosition,
      homeLocation: (state) => state["home"].defaultLocation,
    }),
  },
  watch: {
    homeLocation() {
      this.fetchStore();
    },
  },

  methods: {
    ...mapActions({
      fetchNewStoreData: "product/fetchNewStores",
      fetchAllItemsData: "product/fetchAllItems",
    }),
    fetchStore() {
      const payload = {
        category: "SELLER",
        name: "",
        state: "",
        city: "",
        zip: "",
        street: "",
        market: "",
        lat: this.currentPosition.Lat || 0,
        lng: this.currentPosition.Lng || 0,
        radius: 6000,
        newlyAdded: "n",
        refresh: true,
      };
      this.fetchNewStoreData(payload);
    },
  },
  mounted() {
    //this.fetchNewStoreData();
    this.fetchStore();
    this.fetchAllItemsData();
  },
};
</script>
<style scoped>
.product-listing-div {
  display: block !important;
  width: 100% !important;
}
</style>
