var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _c('div', {
    attrs: {
      "else": ""
    }
  }, [_vm.$route.params.items == "New Stores" ? _c('sections-slider', {
    attrs: {
      "list": _vm.newStore,
      "subTitle": _vm.$route.params.items,
      "hasBackground": true,
      "showTitle": true,
      "hasButton": false,
      "withoutSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('div', {
          staticClass: "item-box product-listing-div"
        }, [_c('router-link', {
          staticStyle: {
            "text-decoration": "none",
            "color": "inherit"
          },
          attrs: {
            "to": {
              name: "shopDetails",
              params: {
                id: listItem._id,
                name: listItem.office.officeName
              }
            }
          }
        }, [_c('items-item', {
          attrs: {
            "itemWhiteBg": true,
            "product": listItem
          }
        })], 1)], 1)];
      }
    }], null, false, 2390524651)
  }) : _vm._e(), _vm.$route.params.items == "Suggested For You" ? _c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.itemSuggestions,
      "subTitle": _vm.$route.params.items,
      "hasBackground": true,
      "hasButton": false,
      "showTitle": true,
      "withoutSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var listItem = _ref2.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          staticClass: "suggester-div w-100",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }], null, false, 3011094852)
  }) : _vm._e(), _vm.$route.params.items == "What's Trending" ? _c('sections-slider', {
    staticClass: "suggested-slider",
    attrs: {
      "list": _vm.trendingItems,
      "subTitle": _vm.$route.params.items,
      "hasBackground": true,
      "hasButton": false,
      "showTitle": true,
      "withoutSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var listItem = _ref3.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-item', {
          staticClass: "suggester-div w-100",
          attrs: {
            "itemNoBg": true,
            "product": listItem,
            "incrementDecrementKey": true
          }
        })], 1)];
      }
    }], null, false, 3011094852)
  }) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }